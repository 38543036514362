import * as React from 'react';
import { TButtonSize } from '@cian/ui-kit/button/types';
import { useDispatch, useSelector } from 'react-redux';

import { setNewbuildingId, toggleChat, toggleJumpAnimation } from '../../actions/consultantChat';
import { MessageButton } from '../../components/MessageButton';
import { chatOpenSelector } from '../../selectors/consultantChat';
import { ChatOnboardingContainer } from '../ChatOnboardingContainer';
import { trackClickChatOpen } from './tracking';

interface IConsultantChatButtonContainerProps {
  buttonSize?: TButtonSize;
  newbuildingId: number;
  isPromoSnippet?: boolean;
}

export const ConsultantChatButtonContainer: React.FC<IConsultantChatButtonContainerProps> = ({
  buttonSize = 'XS',
  newbuildingId,
  isPromoSnippet = false,
}) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(chatOpenSelector);

  const handleOpen = React.useCallback(() => {
    if (isOpen) {
      dispatch(toggleJumpAnimation(true));
    } else {
      dispatch(toggleChat(true));
      dispatch(setNewbuildingId(newbuildingId));
      trackClickChatOpen(newbuildingId);
    }
  }, [dispatch, isOpen, newbuildingId]);

  const messageButton = (
    <MessageButton buttonSize={buttonSize} onClick={handleOpen}>
      Написать
    </MessageButton>
  );

  if (isPromoSnippet) {
    return (
      <ChatOnboardingContainer type="consultant" placement="top-start">
        {messageButton}
      </ChatOnboardingContainer>
    );
  }

  return messageButton;
};
