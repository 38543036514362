import { INewbuildingFeatureV2 } from '../../types/newbuilding';
import { IApplicationState } from '../../types/redux';
import { getNewbuildingByIdSelector } from './getNewbuildingByIdSelector';

export const getNewbuildingFeaturesByIdSelector =
  (newbuildingId: number) =>
  (state: IApplicationState): INewbuildingFeatureV2[] | null | undefined => {
    const nb = getNewbuildingByIdSelector(newbuildingId)(state);

    return nb.newbuildingFeaturesV2;
  };
