import { EOffersActions, INewbuildingsAction } from '../../actions/offersData';
import { prepareNewbuildings } from '../../mappers/prepareNewbuildings';
import { INewbuildingsState } from '../../types/newbuilding/newbuildingsState';

const defaultNewbuildings: INewbuildingsState = {
  allIds: [],
  byId: {},
  allSimilarNewbuildingsIds: [],
};

export function newbuildings(state: INewbuildingsState = defaultNewbuildings, action: INewbuildingsAction) {
  switch (action.type) {
    case EOffersActions.set:
      return prepareNewbuildings(action.data);
    default:
      return { ...state };
  }
}
