import * as React from 'react';
import { LabelsContainer } from '../LabelsContainer';
import { Features } from './Features';
import { useSelector } from 'react-redux';
import { getNewbuildingImagesByIdSelector, getNewbuildingNameByIdSelector } from '../../selectors/newbuilding';
import { Image } from '../../components/Image';
import { CarouselBlock } from '../../components/CarouselBlock';
import { Carousel } from '../../components/Carousel';
import { IImageInfo } from '../../types/newbuilding';
import { ISlideProps } from '../../components/Carousel/Carousel';

interface ICarouselProps {
  newbuildingId: number;
}

export const CarouselContainer = ({ newbuildingId }: ICarouselProps) => {
  const newbuildingImages = useSelector(getNewbuildingImagesByIdSelector(newbuildingId));
  const name = useSelector(getNewbuildingNameByIdSelector(newbuildingId));

  if (!newbuildingImages || !newbuildingImages.length) {
    return (
      <CarouselBlock
        features={<Features newbuildingId={newbuildingId} />}
        labels={<LabelsContainer offerId={newbuildingId} />}
      >
        <Image lazy />
      </CarouselBlock>
    );
  }

  return (
    <CarouselBlock
      features={<Features newbuildingId={newbuildingId} />}
      labels={<LabelsContainer offerId={newbuildingId} />}
    >
      <Carousel
        slides={newbuildingImages.map(
          (img: IImageInfo, index: number) =>
            // eslint-disable-next-line react/display-name
            ({ visible }: ISlideProps) =>
              visible && (
                <Image alt={`${index + 1}-я Фотография ${name}`} src={img.thumbnailUrl} lazy objectFit="cover" />
              ),
        )}
        defaultSlideIndex={newbuildingImages.findIndex((img: IImageInfo) => img.isDefault)}
      />
    </CarouselBlock>
  );
};
