import { actionGenerator } from '../../utils/actionGenerator';

export enum EBuilderChatActionTypes {
  OpenChat = 'builderChat/open',
  CloseChat = 'builderChat/close',
  ForceHideOnboarding = 'builderChat/forceHideOnboarding',
}

export interface IOpenBuilderChatPayload {
  builderRealtyUserId?: number | null;
  gaLabel: string;
  defaultMessage?: string;
}

export const openBuilderChat = actionGenerator<EBuilderChatActionTypes.OpenChat, IOpenBuilderChatPayload>(
  EBuilderChatActionTypes.OpenChat,
);

export const closeBuilderChat = actionGenerator<EBuilderChatActionTypes.CloseChat, void>(
  EBuilderChatActionTypes.CloseChat,
);

export const forceHideOnboarding = actionGenerator<EBuilderChatActionTypes.ForceHideOnboarding, void>(
  EBuilderChatActionTypes.ForceHideOnboarding,
);

export type TBuilderChatActions =
  | ReturnType<typeof openBuilderChat>
  | ReturnType<typeof closeBuilderChat>
  | ReturnType<typeof forceHideOnboarding>;
