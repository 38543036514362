import * as React from 'react';

import { PhoneButton } from './PhoneButton';

interface IShowPhoneButtonProps {
  newbuildingId: number;
}

export const ShowPhoneButton = ({ newbuildingId }: IShowPhoneButtonProps) => {
  return <PhoneButton newbuildingId={newbuildingId} />;
};
