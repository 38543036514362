import { IApplicationState } from '../../types/redux';
import { getNewbuildingPromoSnippet } from '../newbuildingPromoSnippet/getNewbuildingPromoSnippet';
import { getIsNewbuildingPromoSnippetDisplayed, getIsNewbuildingPromoSnippetEnabled } from '../newbuildingPromoSnippet';

export const getIsNewbuildingHiddenByIdSelector =
  (newbuildingId: number) =>
  (state: IApplicationState): boolean => {
    const page = state.location.page;

    const newbuildingPromoSnippet = getNewbuildingPromoSnippet(state);
    const isNewbuildingPromoSnippetEnabled = getIsNewbuildingPromoSnippetEnabled(state);
    const isNewbuildingPromoSnippetDisplayed = getIsNewbuildingPromoSnippetDisplayed(state);

    const isPromoNewbuildingDisplayed = isNewbuildingPromoSnippetEnabled && isNewbuildingPromoSnippetDisplayed;
    const promoNewbuilding = newbuildingPromoSnippet?.newbuilding;

    return Boolean(
      page === 1 && isPromoNewbuildingDisplayed && promoNewbuilding && promoNewbuilding.id === newbuildingId,
    );
  };
