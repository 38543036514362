import { IApplicationState } from '../../types/redux';
import { getNewbuildingByIdSelector } from './getNewbuildingByIdSelector';

export const getNewbuildingNameByIdSelector =
  (newbuildingId: number) =>
  (state: IApplicationState): string => {
    const nb = getNewbuildingByIdSelector(newbuildingId)(state);

    return nb.displayName || nb.name;
  };
