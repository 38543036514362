import * as React from 'react';

import { INewbuildingFeatureV2 } from '../../types/newbuilding';
import { NewbuildingFeature } from './NewbuildingFeature';

import * as styles from './NewbuildingFeatures.css';

interface INewbuildingFeaturesProps {
  features: INewbuildingFeatureV2[];
  url: string;
  onClick?(type: string): void;
}

export const NewbuildingFeatures = (props: INewbuildingFeaturesProps) => {
  const { features, url, onClick } = props;

  return (
    <div data-testid="features" className={styles['container']}>
      {features.map((feature, idx) => {
        return (
          <NewbuildingFeature
            key={idx}
            type={feature.type}
            title={feature.text}
            url={feature.link || url}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};
