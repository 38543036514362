import { IConsultantChat } from '../../types/consultantChat';
import { ENBConsutantActions, TConsultantChatActions } from '../../actions/consultantChat';
import { EBuilderChatActionTypes, TBuilderChatActions } from '../../actions/builderChat';

export const defaultState: IConsultantChat = {
  accountName: '',
  chatOpen: false,
  chatNeedForceOpen: false,
  chatNeedRunJumpAnimation: false, // TODO: Удалить в CD-225149, если эксперимент проиграет.
};

export function consultantChat(
  state: IConsultantChat = defaultState,
  action: TConsultantChatActions | TBuilderChatActions,
) {
  switch (action.type) {
    case ENBConsutantActions.ToggleChat:
      return {
        ...state,
        chatOpen: action.payload,
      };
    case ENBConsutantActions.ToggleJumpAnimation: {
      return {
        ...state,
        chatNeedRunJumpAnimation: action.payload,
      };
    }
    case ENBConsutantActions.SetNewbuildingId:
      return {
        ...state,
        newbuildingId: action.payload,
      };

    // Закрываем чат с консультантом, если открывается чат с застройщиком.
    case EBuilderChatActionTypes.OpenChat:
      return {
        ...state,
        chatOpen: false,
      };
    default:
      return state;
  }
}
