import { IAdfoxPuidsMap } from './adfox';
import { INewbuilding, IBuilderStats, INewbuildingPromoSnippet } from './newbuilding';
import { ICompany } from './company';
import { IPromoPopup } from './promoPopup';
import type { IYMEventsSchema } from '../repositories/newbuilding-search/entities/ym_events/YMEventsSchema';

export type TGeoType = 'underground' | 'location';

export interface ISeo {
  description: string;
  uri: string;
  keywords: string;
  h1: string;
  title: string;
  subtitle: string;
  subtitles: string[];
  canonical?: string;
  geoType: TGeoType;
  text: string;
  seoLinks?: ISeoLink[];
  nonGeoQuery: string;
  query: string;
  ogTitle: string;
  markedList?: string[];
  markedListV2?: IMarkedList;
  keyPhrases?: string[];
  feature: string;
  locations?: Array<{
    id: number;
    type: string;
  }>;
  geoId?: number;
  geoTypeId?: number;
  aggregateRating?: number | null;
  reviewsCount?: number | null;
}

export interface ISeoLink {
  title: string;
  url: string;
}

export interface IBreadcrumb {
  title: string;
  url: string;
}

export interface ISalesDescription {
  salesType: ESalesType;
  text: string;
}

export interface ISalesLink {
  salesType: ESalesType;
  text: string;
  title: string;
  url: string;
}

export enum ESalesType {
  /** Чёрная пятница **/
  BlackFriday = 'blackFriday',
  /** Подбор новостроек */
  ConsultantPage = 'consultantPage',
  /** Главная каталога акций **/
  PromoIndexPage = 'promoIndexPage',
  /** Лидеры продаж **/
  SalesLeader = 'salesLeader',
  /** Старт продаж **/
  SalesStart = 'salesStart',
  /** Скоро в продаже **/
  UpcomingSale = 'upcomingSale',
  /** Льготная ипотека **/
  'DiscountMortgage' = 'discountMortgage',
}

export interface IMarkedList {
  items: IMarkedListItem[];
  schema?: string;
}

export interface IMarkedListItem {
  content: string;
  title: string;
  expanded: boolean;
  underH1: boolean;
}

export interface ISeoSchemas {
  mortgageDiscount: string;
  onlineShow: string;
  organizationInfo: string;
  wideSelection: string;
  product: string;
  breadcrumbs: string;
}

export interface IOffersDataState {
  breadcrumbs: IBreadcrumb[];
  newbuildings: INewbuilding[];
  newbuildingPromoSnippet?: INewbuildingPromoSnippet | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  similarNewbuildings: any[];
  seo: ISeo | null;
  seoSchemas?: ISeoSchemas | null;
  builder: ICompany | null;
  builderStats: IBuilderStats | null;
  fullUrl: string;
  total: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newbuildingBuilderStats?: any[];
  queryString: string;
  mapFullUrl?: string | null;
  salesDescription?: ISalesDescription | null;
  salesLeaderFullUrl: string;
  salesLinks?: ISalesLink[] | null;
  newbuildingIdsForBuildersPromoSlider: number[];
  puids?: IAdfoxPuidsMap | null;
  discountMortgagePopup?: IPromoPopup | null;
  /** События для Яндекс.Метрики **/
  ymEvents?: IYMEventsSchema | null;
}
