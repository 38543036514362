import { IApplicationState } from '../../types/redux';
import { INewbuilding } from '../../types/newbuilding';

export const getNewbuildingByIdSelector =
  (newbuildingId: number) =>
  (state: IApplicationState): INewbuilding => {
    const { byId } = state.newbuildings;

    return byId[newbuildingId];
  };
