import { IOffersDataState } from '../types/offersData';
import { INewbuildingsState } from '../types/newbuilding/newbuildingsState';

export const prepareNewbuildings = (offersData: IOffersDataState): INewbuildingsState => {
  const allIds: INewbuildingsState['allIds'] = [];
  const allSimilarNewbuildingsIds: INewbuildingsState['allSimilarNewbuildingsIds'] = [];
  const byId: INewbuildingsState['byId'] = {};
  const newbuildingsLen = offersData?.newbuildings?.length || 0;
  const similarNewbuildingsLen = offersData?.similarNewbuildings?.length || 0;

  for (let i = 0; i < newbuildingsLen; i++) {
    const newbuilding = offersData.newbuildings[i];
    const id: number = newbuilding.id;
    allIds.push(id);
    byId[id] = newbuilding;
  }
  for (let i = 0; i < similarNewbuildingsLen; i++) {
    const similarNewbuilding = offersData.similarNewbuildings[i];
    const id: number = similarNewbuilding.id;
    allSimilarNewbuildingsIds.push(id);
    byId[id] = similarNewbuilding;
  }

  return {
    allIds,
    allSimilarNewbuildingsIds,
    byId,
  };
};
