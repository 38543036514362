import * as React from 'react';
import { ShowPhoneButton } from '../ShowPhoneButton';

interface IShowPhoneButtonContainerProps {
  newbuildingId: number;
}

export function ShowPhoneButtonContainer(props: IShowPhoneButtonContainerProps) {
  const { newbuildingId } = props;

  return <ShowPhoneButton newbuildingId={newbuildingId} />;
}
