import { IImageInfo } from '../../types/newbuilding';
import { IApplicationState } from '../../types/redux';
import { getNewbuildingByIdSelector } from './getNewbuildingByIdSelector';

export const getNewbuildingImagesByIdSelector =
  (newbuildingId: number) =>
  (state: IApplicationState): IImageInfo[] | null | undefined => {
    const nb = getNewbuildingByIdSelector(newbuildingId)(state);

    return nb.images;
  };
