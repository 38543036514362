import { INewbuilding, ISeller, IBuilder } from '../../../types/newbuilding';
import { ISeller as ISellerMaped } from '@cian/frontend-newbuilding-call-component/es/types/seller';
import { IBuilder as IBuilderMapped } from '@cian/frontend-newbuilding-call-component/es/types/builder';

interface IMappedData {
  sellers: ISellerMaped[];
  builders: IBuilderMapped[];
}

interface IPhone {
  code: string;
  countryCode: string;
  number: string;
}

const mapPhone = ({ phone }: { phone: string }): IPhone => {
  return {
    countryCode: phone.substring(0, 1),
    code: phone.substring(1, 4),
    number: phone.substring(4),
  };
};

const _mapSeller = (rawSeller: ISeller): ISellerMaped => {
  return {
    avatarUrl: rawSeller.avatarUrl,
    dynamicCalltracking: rawSeller.newbuildingDynamicCalltracking,
    isLeadFactory: rawSeller.isLeadFactory || false,
    name: rawSeller.name,
    realtyUserId: rawSeller.realtyUserId,
    showPhone: true,
    phone: mapPhone({ phone: rawSeller.phone }),
    workTime: 'workTime',
  };
};

const _mapBuilder = (rawBuilder: IBuilder): IBuilderMapped => {
  return {
    id: rawBuilder.id,
    isFichering: false,
    logoUrl: rawBuilder.logoUrl,
    name: rawBuilder.name,
    dynamicCalltracking: rawBuilder.newbuildingDynamicCalltracking,
    realtyUserId: 0,
    showPhone: true,
    phone: mapPhone({ phone: rawBuilder.phone as string }),
    workTime: 'workTime',
  };
};

export const usePhoneData = (newbuilding: INewbuilding): IMappedData => {
  const rawSellers = newbuilding?.sellers
    ? newbuilding.sellers.filter(({ isBuilder, phone }) => !isBuilder && phone)
    : [];
  const rawBuilders = newbuilding?.builders ? newbuilding.builders.filter(({ phone }) => phone) : [];

  return {
    sellers: rawSellers.map(_mapSeller),
    builders: rawBuilders.map(_mapBuilder),
  };
};
