import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestAuthentication } from '../../actions/authentication';
import { closeBuilderChat, forceHideOnboarding, openBuilderChat } from '../../actions/builderChat';
import { getUser } from '../../selectors/user';
import { TTypedThunkDispatch } from '../../types/thunk';

type TUseBuilderChatParams = { defaultMessage?: string; gaLabel: string; builderRealtyUserId?: number | null };

export function useBuilderChat(params: TUseBuilderChatParams) {
  const dispatch: TTypedThunkDispatch = useDispatch();
  const user = useSelector(getUser);

  const openChat = React.useCallback(() => {
    // TODO: Удалить в CD-226276/CD-225149.
    dispatch(forceHideOnboarding());

    if (!user.isAuthenticated) {
      dispatch(
        requestAuthentication({
          onSuccess: () => openBuilderChat(params),
          onCloseNoAuth: closeBuilderChat,
        }),
      );

      return;
    }

    dispatch(openBuilderChat(params));
  }, [dispatch, user, params]);

  return React.useMemo(
    () => ({
      openChat,
    }),
    [openChat],
  );
}
