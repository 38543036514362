import * as React from 'react';
import { EPageTypes, EPlatformTypes, FicheringCall } from '@cian/frontend-newbuilding-call-component';
import { useSelector } from 'react-redux';

import { ApplicationContext } from '../../utils/applicationContext';
import {
  getNewbuildingPromoSnippetBuilders,
  getNewbuildingPromoSnippetSellers,
} from '../../selectors/newbuildingPromoSnippet';

interface ICallProps {
  onShowPhone(): void;
  onShowContacts(): void;
}

export function Call({ onShowPhone, onShowContacts }: ICallProps) {
  const context = React.useContext(ApplicationContext);
  const builders = useSelector(getNewbuildingPromoSnippetBuilders);
  const sellers = useSelector(getNewbuildingPromoSnippetSellers);
  const placeType = 'newbuildingPromoSnippetContacts';

  return (
    <FicheringCall
      builders={builders}
      sellers={sellers}
      platformType={EPlatformTypes.WebDesktop}
      pageType={EPageTypes.NewbuildingList}
      httpApi={context.httpApi}
      logger={context.logger}
      onShowPhoneClick={onShowPhone}
      onOpenContactsClick={onShowContacts}
      placeType={placeType}
    />
  );
}
