import * as React from 'react';
import * as style from './style.css';

export const GkCardLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div data-name="GKCardComponent" className={style['layout']} data-testid="GKCard" data-mark="GKCard">
      {children}
    </div>
  );
};
