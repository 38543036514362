import { actionGenerator } from '../../utils/actionGenerator';

export enum ENBConsutantActions {
  ToggleChat = 'consultantChat/toggleChat',
  ToggleJumpAnimation = 'consultantChat/toggleJumpAnimation',
  SetNewbuildingId = 'consultantChat/setNewbuildingId',
}

export const toggleChat = actionGenerator<ENBConsutantActions.ToggleChat, boolean>(ENBConsutantActions.ToggleChat);

export const toggleJumpAnimation = actionGenerator<ENBConsutantActions.ToggleJumpAnimation, boolean>(
  ENBConsutantActions.ToggleJumpAnimation,
);

export const setNewbuildingId = actionGenerator<ENBConsutantActions.SetNewbuildingId, number>(
  ENBConsutantActions.SetNewbuildingId,
);

export type TConsultantChatActions =
  | ReturnType<typeof toggleChat>
  | ReturnType<typeof toggleJumpAnimation>
  | ReturnType<typeof setNewbuildingId>;
