import { ca } from '@cian/analytics';

export const trackClickChatOpen = (productId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'WebIM',
    action: 'Click',
    label: 'StartChat',
    page: {
      extra: {
        productId,
      },
    },
  });
};
