import * as React from 'react';
import * as styles from './SinglePhoneComponent.css';
import { UIText2 } from '@cian/ui-kit';

export const SinglePhoneComponent = ({ phone }: { phone: string }) => {
  return (
    <div data-testid="SinglePhoneComponent" className={styles['phone']}>
      <UIText2 fontWeight="bolder">{phone}</UIText2>
    </div>
  );
};
