import * as React from 'react';
import * as styles from './PromoSnippetLayout.css';
import { MIDDLE_DOT, NO_BREAK_SPACE } from '../../constants/symbolsUnicode';

interface IPromoSnippetLayoutProps {
  preview: React.ReactNode;
  labels: React.ReactNode;
  title: React.ReactNode;
  reviews: React.ReactNode;
  salesStartLabel: React.ReactNode;
  underground: React.ReactNode;
  address: React.ReactNode;
  advertises: React.ReactNode;
  callButton: React.ReactNode;
  messageButton?: React.ReactNode;
  favoriteButton: React.ReactNode;
  roomCounts: React.ReactNode;
  specialStatusDisplay: string;
  offerTypes?: string | null;
}

export function PromoSnippetLayout({
  preview,
  reviews,
  title,
  labels,
  specialStatusDisplay,
  salesStartLabel,
  address,
  underground,
  callButton,
  messageButton,
  favoriteButton,
  roomCounts,
  advertises,
  offerTypes,
}: IPromoSnippetLayoutProps) {
  return (
    <div className={styles.container} data-testid="PromoSnippet">
      <div className={styles.preview}>{preview}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        {labels && <div className={styles.labels}>{labels}</div>}
        <div className={styles.other}>
          {reviews && (
            <div data-testid="promo_snippet_reviews" className={styles['reviews']}>
              {reviews}
            </div>
          )}
          {reviews && specialStatusDisplay && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}`}
          {specialStatusDisplay}
          {offerTypes && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}${offerTypes}`}
          {salesStartLabel && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}`}
          {salesStartLabel}
        </div>
        <div className={styles.underground}>{underground}</div>
        <div className={styles.address}>{address}</div>
        {advertises && <div className={styles.advertises}>{advertises}</div>}
        <div className={styles.footer}>
          <div className={styles.call}>{callButton}</div>
          <div className={styles.message}>{messageButton}</div>
          <div className={styles.favorite}>{favoriteButton}</div>
          <div className={styles.rooms}>{roomCounts}</div>
        </div>
      </div>
    </div>
  );
}
