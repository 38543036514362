import * as React from 'react';
import { useSelector } from 'react-redux';
import { useVisitedOffersContext } from '@cian/frontend-visited-offers-widget';

import {
  getNewbuildingPromoSnippetTitle,
  getNewbuildingExtra,
  getIsVisiblePromoSnippetReviews,
  getNewbuildingPromoSnippetNewbuilding,
  getIsNewbuildingPromoSnippetDisplayed,
  getIsNewbuildingPromoSnippetEnabled,
  getNewbuildingPromoSnippetExtensionFields,
} from '../../selectors/newbuildingPromoSnippet';

import { Call } from '../Call';
import { RoomCounts } from '../OffersContainer/RoomCounts';
import { FavoriteButton } from '../OffersContainer/FavoriteButton';
import { SalesStartLabelContainer } from '../SalesStartLabelContainer';
import { ReviewLinkContainer } from '../ReviewLinkContainer';

import { Image } from '../../components/Image';
import { DeveloperLogo } from '../../components/DeveloperLogo';
import { PromoSnippetTitle } from '../../components/PromoSnippetTitle';
import { PromoSnippetLayout } from '../../components/PromoSnippetLayout';
import { PromoSnippetLabels } from '../../components/PromoSnippetLabels';
import { NewbuildingFeatures } from '../../components/NewbuildingFeatures';
import { PromoSnippetAddress } from '../../components/PromoSnippetAddress';
import { PromoSnippetCarousel } from '../../components/PromoSnippetCarousel';
import { PromoSnippetAdvertises } from '../../components/PromoSnippetAdvertises';
import { PromoSnippetUnderground } from '../../components/PromoSnippetUnderground';

import {
  PromoSnippetFavoriteActionNames,
  trackPromoSnippetShow,
  trackPromoSnippetBuilderClick,
  trackPromoSnippetJkClick,
  trackPromoSnippetDeveloperFlatsClick,
  trackPromoSnippetPhoneNumberShow,
  trackPromoSnippetFeaturesClick,
  trackPromoSnippetContactsShow,
  trackPromoSnippetReviewsClick,
} from './tracking';
import { getCurrentJsonQuery } from '../../selectors/location';
import { INewbuilding, INewbuildingPromoSnippetExtensionFields } from '../../types/newbuilding';
import { isBuilderChatEnabledByNewbuilding } from '../../utils/builderChat';
import { LabelsContainer } from '../LabelsContainer';
import { MessageButtonContainer } from '../MessageButtonContainer';

export function PromoSnippetContainer() {
  const newbuilding = useSelector(getNewbuildingPromoSnippetNewbuilding);
  const isNewbuildingPromoSnippetEnabled = useSelector(getIsNewbuildingPromoSnippetEnabled);
  const isNewbuildingPromoSnippetDisplayed = useSelector(getIsNewbuildingPromoSnippetDisplayed);
  const extensionFields = useSelector(getNewbuildingPromoSnippetExtensionFields);

  const isSnippetDisplayed = isNewbuildingPromoSnippetEnabled && isNewbuildingPromoSnippetDisplayed;

  if (!newbuilding || !isSnippetDisplayed || !extensionFields) {
    return null;
  }

  return <PromoSnippetContentContainer />;
}

export function PromoSnippetContentContainer() {
  const newbuilding = useSelector(getNewbuildingPromoSnippetNewbuilding) as INewbuilding;
  const extensionFields = useSelector(
    getNewbuildingPromoSnippetExtensionFields,
  ) as INewbuildingPromoSnippetExtensionFields;
  const {
    id,
    url,
    salesStartLabel,
    specialStatusDisplay,
    undergroundInfo,
    addressLine,
    isSalesLeader,
    isUpcomingSale,
    fromDevelopersRooms,
    isFavorite,
    gaLabels,
    fromDeveloperPropsLink,
    fromAgentsPropsLink,
    fromAgentsPropsCount,
    fromAgentsCountsDisplay,
    fromDeveloperPropsCount,
    labels,
    isProblem,
    offerTypes,
  } = newbuilding;

  const { images } = extensionFields;

  const title = useSelector(getNewbuildingPromoSnippetTitle);
  const newbuildingExtra = useSelector(getNewbuildingExtra);
  const hasBuilders = newbuilding.builders && newbuilding.builders.length !== 0;
  const jsonQuery = useSelector(getCurrentJsonQuery);
  const isVisibllePromoSnippetReviews = useSelector(getIsVisiblePromoSnippetReviews);
  const isBuilderChatEnabled = isBuilderChatEnabledByNewbuilding(newbuilding);

  const { setOfferVisited } = useVisitedOffersContext();

  React.useEffect(() => {
    trackPromoSnippetShow(id, jsonQuery);
  }, []);

  const handleBuilderClick = () => {
    trackPromoSnippetBuilderClick(id, jsonQuery);
  };

  const handleJkClick = () => {
    trackPromoSnippetJkClick(id, jsonQuery);

    setOfferVisited(id);
  };

  const handleReviewsClick = () => {
    trackPromoSnippetReviewsClick(id, jsonQuery);
  };

  const handleFeaturesClick = (action: string) => {
    trackPromoSnippetFeaturesClick(id, action, jsonQuery);
  };

  const handleFlatClick = (room: string) => {
    trackPromoSnippetDeveloperFlatsClick({ newbuildingId: id, room, jsonQuery });
  };

  const handlePhoneNumberShow = () => {
    trackPromoSnippetPhoneNumberShow(id, gaLabels.full, jsonQuery);
  };

  const handleContactsShow = () => {
    trackPromoSnippetContactsShow(id, gaLabels.full, newbuildingExtra, jsonQuery);
  };

  return (
    <PromoSnippetLayout
      title={<PromoSnippetTitle title={title} url={url} onClick={handleJkClick} />}
      specialStatusDisplay={specialStatusDisplay}
      offerTypes={offerTypes}
      salesStartLabel={salesStartLabel && <SalesStartLabelContainer newbuildingId={id} description={salesStartLabel} />}
      preview={
        images ? (
          <PromoSnippetCarousel
            key={newbuilding.id}
            features={
              newbuilding.newbuildingFeaturesV2 && (
                <NewbuildingFeatures
                  features={newbuilding.newbuildingFeaturesV2}
                  url={newbuilding.url}
                  onClick={handleFeaturesClick}
                />
              )
            }
            labels={<LabelsContainer offerId={newbuilding.id} promo />}
            logo={
              hasBuilders && (
                <DeveloperLogo
                  url={newbuilding.builders[0].url}
                  logoUrl={newbuilding.builders[0].logoUrl}
                  name={newbuilding.builders[0].name}
                  onClick={handleBuilderClick}
                />
              )
            }
            title={title}
            images={images}
          />
        ) : (
          <Image />
        )
      }
      reviews={
        isVisibllePromoSnippetReviews && (
          <ReviewLinkContainer
            onClick={handleReviewsClick}
            newbuildingId={newbuilding.id}
            newbuildingUrl={url}
            newbuilding={newbuilding}
          />
        )
      }
      underground={undergroundInfo && <PromoSnippetUnderground newbuilding={newbuilding} />}
      address={addressLine && <PromoSnippetAddress newbuilding={newbuilding} />}
      labels={
        <PromoSnippetLabels
          isSalesLeader={isSalesLeader}
          labels={labels}
          id={id}
          url={url}
          hasProblem={Boolean(isProblem)}
        />
      }
      advertises={
        !isUpcomingSale && (
          <PromoSnippetAdvertises
            key={newbuilding.id}
            onClick={handleFlatClick}
            fromDevelopersRooms={fromDevelopersRooms}
          />
        )
      }
      callButton={<Call onShowContacts={handleContactsShow} onShowPhone={handlePhoneNumberShow} />}
      messageButton={<MessageButtonContainer newbuilding={newbuilding} buttonSize="M" isPromoSnippet={true} />}
      favoriteButton={
        <FavoriteButton
          isFavorite={Boolean(isFavorite)}
          newbuildingId={id}
          isPromo={true}
          gaLabelFull={gaLabels.full}
          analyticsActionAddName={PromoSnippetFavoriteActionNames.Add}
          analyticsActionRemoveName={PromoSnippetFavoriteActionNames.Remove}
          isBuilderChatEnabled={isBuilderChatEnabled}
        />
      }
      roomCounts={
        <RoomCounts
          isPromo={true}
          fromDeveloperPropsLink={fromDeveloperPropsLink}
          fromAgentsPropsLink={fromAgentsPropsLink}
          fromAgentsPropsCount={fromAgentsPropsCount}
          fromAgentsCountsDisplay={fromAgentsCountsDisplay}
          fromDeveloperPropsCount={fromDeveloperPropsCount}
        />
      }
    />
  );
}
