import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewbuildingByIdSelector } from '../../selectors/newbuilding/getNewbuildingByIdSelector';
import { EPageTypes, EPlatformTypes, SimpleCall } from '@cian/frontend-newbuilding-call-component';
import { useApplicationContext } from '../../utils/applicationContext';
import { usePhoneData } from './hooks/usePhoneData';
import { SinglePhoneComponent } from './SinglePhoneComponent';
import { trackingActionOpenContacts, trackingActionShowPhone } from '../../actions/analytics';
import { TTypedThunkDispatch } from '../../types/thunk';

interface IPhoneButtonProps {
  newbuildingId: number;
}

export const PhoneButton = ({ newbuildingId }: IPhoneButtonProps) => {
  const { httpApi, logger } = useApplicationContext();
  const dispatch: TTypedThunkDispatch = useDispatch();

  const newbuilding = useSelector(getNewbuildingByIdSelector(newbuildingId));

  const { builders, sellers } = usePhoneData(newbuilding);
  const isPhoneWithoutPopup = builders.length + sellers.length <= 1;

  const trackingOnPhoneClick = () => {
    dispatch(trackingActionShowPhone(newbuilding));
  };
  const trackingOpenContacts = () => {
    dispatch(trackingActionOpenContacts(newbuilding.gaLabels.full));
  };

  /* istanbul ignore next */
  if (!builders.length && !sellers.length) {
    return null;
  }

  return (
    <div>
      <SimpleCall
        SinglePhoneComponent={SinglePhoneComponent}
        openContactsStyles={{ size: 'XS' }}
        showPhoneStyles={{ size: 'XS' }}
        withoutModal={isPhoneWithoutPopup}
        httpApi={httpApi}
        logger={logger}
        platformType={EPlatformTypes.WebDesktop}
        pageType={EPageTypes.NewbuildingList}
        placeType="ShowPhoneButton"
        builders={builders}
        sellers={sellers}
        onShowPhoneClick={trackingOnPhoneClick}
        onOpenContactsClick={trackingOpenContacts}
      />
    </div>
  );
};
