import * as React from 'react';

import * as styles from './OffersLayout.css';

export const OffersLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  // TODO: Удалить id атрибут в CD-226276.
  return (
    <div className={styles['container']} id="offers-layout">
      {children}
    </div>
  );
};
