import * as React from 'react';
import { NewbuildingFeatures } from '../../components/NewbuildingFeatures';
import { useSelector } from 'react-redux';
import { getNewbuildingFeaturesByIdSelector, getNewbuildingUrlByIdSelector } from '../../selectors/newbuilding';

interface IFeaturesProps {
  newbuildingId: number;
  onClick?(type: string): void;
}

export const Features = ({ newbuildingId, onClick }: IFeaturesProps) => {
  const features = useSelector(getNewbuildingFeaturesByIdSelector(newbuildingId));
  const url = useSelector(getNewbuildingUrlByIdSelector(newbuildingId));

  if (!features) {
    return null;
  }

  return <NewbuildingFeatures features={features} url={url} onClick={onClick} />;
};
