import { is } from 'ramda';
import { ca } from '@cian/analytics';

import { isAuthenticatedUser } from '../../utils/verifications';
import { IApplicationState } from '../../types/redux';
import { INewbuilding } from '../../types/newbuilding';
import { IThunkActionCreator } from '../../types/thunk';
import { TUserType } from '../../types/user';
import { getNewbuildingExtra } from './getNewbuildingExtra';

type TCaEventName = 'oldevent';

type TCaEventCategory = 'Listing_JK' | 'Skin' | 'subscription' | 'ListingJK';

type TCaEventAction =
  | 'to_card_newbuilding'
  | 'to_card_developer'
  | 'click_agentsFlats'
  | 'Map'
  | 'Send'
  | 'popup_GEO_open'
  | 'popup_more_param_open'
  | 'click_sopr'
  | 'click_all_leaders';

type TCaEventLabel = string;

type TTrackingFlats =
  | '1-komnatnye'
  | '2-komnatnye'
  | '3-komnatnye'
  | 'studii'
  | 'all-variants'
  | '4-komnatnye_i_bolee'
  | 'free_planning';

type TTrackingFeaturesTypes =
  | 'reviews'
  | 'hasProgressOfConstructions'
  | 'hasVideo'
  | 'hasMvk'
  | 'has3dTour'
  | 'hasFlatTourBooking';

type TTrackingFlatsNew = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '9' | 'room' | 'part' | 'bed' | 'all';

export const roomstoFlatsMap: { [item: string]: TTrackingFlats | string } = {
  '1-комн.': '1-komnatnye',
  '2-комн.': '2-komnatnye',
  '3-комн.': '3-komnatnye',
  '4-комн.': '4-komnatnye_i_bolee',
  '5-комн.': '4-komnatnye_i_bolee',
  'Многокомн.': '4-komnatnye_i_bolee',
  'Своб. план.': 'free_planning',
  Комнаты: 'room',
  Доли: 'part',
  Студии: 'studii',
  'Койко-места': 'bed',
  'all-variants': 'all-variants',
};

export const roomstoFlatsMapNew: { [item: string]: TTrackingFlatsNew | string } = {
  '1-комн.': '1',
  '2-комн.': '2',
  '3-комн.': '3',
  '4-комн.': '4',
  '5-комн.': '5',
  'Многокомн.': '6',
  'Своб. план.': '7',
  Комнаты: 'room',
  Доли: 'part',
  Студии: '9',
  'Койко-места': 'bed',
  'all-variants': 'all',
};

export const featuresToActionMap: { [item: string]: TTrackingFeaturesTypes | string } = {
  hasVideo: 'to_cardJK',
  hasMvk: 'to_cardJK',
  has3dTour: 'to_cardJK',
  hasFlatTourBooking: 'to_cardJK',
  reviews: 'to_reviewsJK',
  hasProgressOfConstructions: 'to_buildingProcess',
};

interface ICaEventPageBase {
  pageType: 'ListingJK';
  siteType: 'desktop';
  breadCrumbs: string[];
  listingType: 'list';
  sortType: 'default';
  pageNumber: number;
  queryString: string;
  offersQty: number | null;
}

interface ICaEventPage extends ICaEventPageBase {
  dealType: 'sale';
  objectType: 'JK';
  region: string;
}

export interface ICaEventParams {
  name: TCaEventName;
  category: TCaEventCategory;
  action: TCaEventAction;
  label: TCaEventLabel;
  page?: ICaEventPage;
  pageType?: string;
}

export function createTrackingAction(params: Partial<ICaEventParams>) {
  return (): IThunkActionCreator => {
    return async () => {
      ca('event', {
        name: 'oldevent',
        ...params,
      });
    };
  };
}

// $.page по хитростям ЕБЦАНАЛ https://cianru.atlassian.net/wiki/spaces/ML/pages/539886326
export function getTrackingPageBaseParam(state: IApplicationState): ICaEventPageBase {
  const {
    location: { page },
    offersData: { queryString, total },
  } = state;

  return {
    listingType: 'list',
    sortType: 'default',
    pageNumber: page,
    breadCrumbs: [], // TODO добавить после https://cianru.atlassian.net/browse/CD-36542
    queryString,
    offersQty: total,
    pageType: 'ListingJK',
    siteType: 'desktop',
  };
}

// $.page по требованиям СОПР 1.0 https://cianru.atlassian.net/wiki/spaces/ML/pages/443613219
export function getTrackingPageParam(state: IApplicationState): ICaEventPage {
  const {
    region: {
      currentRegion: { id },
    },
  } = state;

  const options: ICaEventPage = {
    ...getTrackingPageBaseParam(state),
    dealType: 'sale',
    objectType: 'JK',
    region: String(id),
  };

  return options;
}

export interface IUserTrackingData {
  cianUserId?: number;
  email?: string;
  type?: TUserType | 'not_authorized';
  userId?: number;
}

// $.user по требованиям для СОПР 1.0 https://cianru.atlassian.net/wiki/spaces/ML/pages/443613219
export function createUserTrackingData(state: IApplicationState) {
  const { user } = state;

  const userTrackingData: IUserTrackingData = {
    type: 'not_authorized',
  };

  if (isAuthenticatedUser(user)) {
    userTrackingData.type = user.userType;
    userTrackingData.email = user.email || undefined;
    userTrackingData.userId = user.userId;
    userTrackingData.cianUserId = user.id;
  }

  return userTrackingData;
}

interface IUserTrackingDataEbc {
  isAuthorized: boolean;
  userId?: number;
}

// $.user по хитростям ЕБЦАНАЛ https://cianru.atlassian.net/wiki/spaces/ML/pages/539886326
export function createUserTrackingDataEbc(state: IApplicationState) {
  const { user } = state;

  const userTrackingData: IUserTrackingDataEbc = {
    isAuthorized: user.isAuthenticated,
  };

  if (isAuthenticatedUser(user)) {
    userTrackingData.userId = user.userId;
  }

  return userTrackingData;
}

// $.products item по требованиям СОПР 1.0 https://cianru.atlassian.net/wiki/spaces/ML/pages/443613219
export function productFromNewbuildingMapper(newbuilding: INewbuilding, index: number) {
  return {
    id: newbuilding.id,
    dealType: 'sale',
    objectType: 'JK',
    position: index + 1,
    name: newbuilding.name,
    nv: newbuilding.showReliableFlag,
    brand: (newbuilding.builders.length && newbuilding.builders[0].id) || undefined,
    consultant: newbuilding.isDealRequestSubstitutionPhone,
    extra: getNewbuildingExtra(newbuilding),
  };
}

// $.products item по хитростям ЕБЦАНАЛ https://cianru.atlassian.net/wiki/spaces/ML/pages/539886326
export function productEbcFromNewbuildingMapper(newbuilding: INewbuilding, index: number) {
  return {
    id: newbuilding.id,
    offerType: 'JK',
    position: index + 1,
    extra: getNewbuildingExtra(newbuilding),
  };
}

export function getTrackingParamsForSopr1(state: IApplicationState) {
  const {
    offersData: { newbuildings },
    analytics: { userGaData },
    region: {
      currentRegion: { mainTownId, fullName, parentId },
      regionsByID,
    },
    jsonQuery,
  } = state;
  // Нет общего интерфейса
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = {
    user: createUserTrackingData(state),
    products: newbuildings.map(productFromNewbuildingMapper),
    page: getTrackingPageParam(state),
    partner: {
      FB_city: is(Number, mainTownId) ? '' : fullName,
      FB_region: mainTownId ? fullName : parentId && regionsByID[parentId] ? regionsByID[parentId].fullName : '',
      CriteoEmail: (userGaData && userGaData.criteoEmail && userGaData.criteoEmail) || '',
      CriteoNewCustomer:
        (userGaData && is(Number, userGaData.criteoNewCustomer) && String(userGaData.criteoNewCustomer)) || '',
      CriteoProducts: newbuildings.slice(0, 3).map(({ id }) => id),
    },
    sc: jsonQuery,
  };

  return options;
}
